<template>
    <div>
        <div class="grid grid-cols-12 gap-4 mt-3">
            <AVInput
                v-model="rlistFilter"
                placeholder="Search for a list"
                class="col-span-10"
                @input="onFilterInputChange"
                @keydown.enter.prevent
            />
            <div class="col-span-2" v-if="rlistStore.isBusy">
                <div className="flex items-center justify-center">
                    <div
                        className="w-10 h-10 border-4 border-primary border-t-transparent rounded-full animate-spin"
                    ></div>
                </div>
            </div>
        </div>
        <div
            v-if="rlistStore.listData"
            class="flex flex-col md:flex-row justify-between items-center my-4"
        >
            <span
                :class="{
                    'text-gray-500':
                        !rlistStore._listRequestTracker.loadedData?.pagination
                            .previous,
                    'cursor-pointer text-primary':
                        !!rlistStore._listRequestTracker.loadedData?.pagination
                            .previous,
                }"
                @click="onClickPrevious"
                ><i
                    class="fi fi-rs-angle-left inline-block mr-2 translate-y-0.5"
                ></i
                >Previous</span
            >
            <span
                :class="{
                    'text-gray-500': rlistFilter === '' && rlistPage === 1,
                    'cursor-pointer text-primary':
                        rlistFilter !== '' || rlistPage !== 1,
                }"
                @click="onClear"
            >
                Clear search
            </span>
            <span
                :class="{
                    'text-gray-500':
                        !rlistStore._listRequestTracker.loadedData?.pagination
                            .next,
                    'cursor-pointer text-primary':
                        !!rlistStore._listRequestTracker.loadedData?.pagination
                            .next,
                }"
                @click="onClickNext"
                >Next<i
                    class="fi fi-rs-angle-right inline-block ml-2 translate-y-0.5"
                ></i
            ></span>
        </div>
        {{}}
        <div
            class="button-group mt-3"
            :class="{
                'flex flex-col': false,
            }"
        >
            <span
                class="options cursor-pointer"
                v-for="(option, index) in rlistStore.listData"
                :class="{
                    'bg-gray-300 text-gray-500': modelValue
                        ?.map((l) => l.id)
                        .includes(option.id),
                    'bg-white text-vanilla': !modelValue
                        ?.map((l) => l.id)
                        .includes(option.id),
                }"
                :key="index"
                @click="toggleList(option)"
            >
                {{ option.name }}
            </span>
        </div>

        <h4 class="inline mb-2 mr-1 text-[15px] font-bold text-gray-900">
            Selected Lists
        </h4>
        <div
            v-if="!props.modelValue || props.modelValue.length == 0"
            class="mb-3 text-gray-500 p-1"
        >
            You have not selected any lists yet
        </div>
        <div v-else>
            <span
                v-for="list in props.modelValue"
                :key="list.id"
                class="options bg-primary text-white cursor-pointer"
                @click="removeList(list as RList)"
                >{{ (list as RList).name }}
            </span>
        </div>
        <AVError v-if="errorTKey" :error-t-key="errorTKey" class="flex-1" />
    </div>
</template>
<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { useRListsBackofficeCrudStore } from '@/AppBackoffice/rlists/stores'
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'
import { ModelReference } from '@/models/types'
import { RList } from '@/models/rlists'
import AVInput from '@/components/forms/AVInput.vue'
import AVError from '@/components/forms/AVError.vue'
import debounce from 'lodash/debounce'

interface Props extends FormFieldProps<Array<ModelReference>> {
    geolocated?: boolean
}
interface Emits extends FormFieldEmits<Array<ModelReference>> {}

const props = withDefaults(defineProps<Props>(), {
    geolocated: false,
})

const emit = defineEmits<Emits>()

const rlistStore = useRListsBackofficeCrudStore()

const rlistPage = ref(1)
const rlistFilter = ref('')

const onFilterSubmit = () => {
    // TODO: consider filtering by geolocated lists using available props
    rlistStore.runList({
        page: rlistPage.value,
        filter: rlistFilter.value,
        geolocation: props.geolocated,
    })
}
const onClear = () => {
    if (rlistFilter.value === '' && rlistPage.value === 1) {
        return
    }
    rlistFilter.value = ''
    rlistPage.value = 1
    onFilterSubmit()
}
onBeforeMount(() => {
    onFilterSubmit()
})

const onFilterInputChange = debounce(() => {
    rlistPage.value = 1
    onFilterSubmit()
}, 750)

const onClickNext = () => {
    if (!rlistStore._listRequestTracker.loadedData?.pagination.next) {
        return
    }
    rlistPage.value++
    onFilterSubmit()
}

const onClickPrevious = () => {
    if (!rlistStore._listRequestTracker.loadedData?.pagination.previous) {
        return
    }
    rlistPage.value--
    onFilterSubmit()
}

const removeList = (list: RList) => {
    emit(
        'update:modelValue',
        (props.modelValue || []).filter((l) => l.id !== list.id)
    )
}

const toggleList = (list: RList) => {
    if (props.modelValue?.map((l) => l.id).includes(list.id)) {
        removeList(list)
        return
    }
    emit('update:modelValue', [...(props.modelValue || []), list])
}
</script>

<style lang="css" scoped>
.options {
    padding: 8px 12px;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
    display: inline-block;
}
</style>
